"use client";

import { useEffect } from "react";
import { Button } from "@/components/ui/Button";
import { Text } from "@/components/ui/Text";
import * as Sentry from "@sentry/nextjs";
export default function GlobalError({
  error,
  reset
}: {
  error: Error & {
    digest?: string;
  };
  reset: () => void;
}) {
  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);
  return <html data-sentry-component="GlobalError" data-sentry-source-file="global-error.tsx">
      <body>
        <Text.H5 data-sentry-element="unknown" data-sentry-source-file="global-error.tsx">Something went wrong!</Text.H5>
        <Button onClick={() => reset()} data-sentry-element="Button" data-sentry-source-file="global-error.tsx">Try again</Button>
      </body>
    </html>;
}